import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { Paper, Box, Typography, Grid } from "@mui/material";

const Project = (props) => {
  const { logo, title, description, linkText, link, width, height } = props;

  return (
    <Paper 
      elevation={3} 
      sx={{
        mixBlendMode: "normal",
        opacity: 0.7,
        width: width, // Dynamic width from props
        height: height, // Dynamic height from props
        bgcolor: "hsla(0, 0%, 100%, 0.5)",
        backdropFilter: "blur(10px) saturate(1.3)",
        borderRadius: "4rem",
        p: "var(--spacing-l) 0 calc(var(--spacing-l) + 2.652rem)",
        m: "var(--spacing-xs) auto calc(var(--spacing-l) - 20px)",
        '&:hover': {
          bgcolor: "#fafafa",
          opacity: 1,
          transition: "background-color 0.3s ease-in-out",
        },
        '& a': {
          textDecoration: "none",
        },
        '&:hover .project-link-text': {
          color: "var(--link-color)",
          transition: "color 0.3s ease-in-out",
        },
      }}
    >
      <Link to={link} style={{ textDecoration: 'none' }}>
        <Box p={3}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Box 
                sx={{
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  overflow: 'hidden'
                }}
              >
                <img src={logo} alt="logo" style={{ maxHeight: '100%', maxWidth: '100%' }} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography 
                variant="h6" 
                sx={{
                  fontFamily: "var(--secondary-font)",
                  color: "var(--primary-color)",
                  fontSize: "16px",
                  pt: "15px",
                  mb: "1em",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ color: "var(--secondary-color)" }}>{description}</Typography>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
              <FontAwesomeIcon icon={faLink} />
              <Typography 
                className="project-link-text" 
                marginLeft={2}
                sx={{ fontWeight: 700 }}
              >
                {linkText}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Link>
    </Paper>
  );
};

export default Project;
