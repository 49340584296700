import React, { useEffect, useRef, useState} from "react";
import { Helmet } from "react-helmet";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ChatBot from "../components/common/chatbot";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { createTheme, ThemeProvider, useTheme} from '@mui/material/styles';

import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/contact.css";

const customTheme = createTheme({
    palette: {
        primary: {
            main: '#005ccc', // Updated color
        },
        // Add other customizations as needed
    },
});



const Contact = () => {
	const theme = useTheme();
    const form = useRef(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_vcokxu7', 'template_422iazd', form.current, 'W3ObIqtQDY_myo4vx')
            .then((result) => {
                setOpen(true); // Open the dialog on successful form submission
                form.current.reset();
            }, (error) => {
                toast.error('Oops, Message Not Sent!', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };

    const handleClose = () => {
        setOpen(false);
    };

	const currentSEO = SEO.find((item) => item.page === 'contact');

	return (
		<ThemeProvider theme={customTheme}>
		<React.Fragment>
			<Helmet>
				<title>{`Contact | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="contact" />
				<div className="content-wrapper">

					<div className="contact-container">
						<div className="title contact-title">
							Contact.
						</div>

						<div className="contact-form-section">
                            
						<Container maxWidth="lg" sx={{ mt: '3vh', mb: '3vh' }}>
                    <form ref={form} onSubmit={sendEmail} noValidate autoComplete="off">
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Name" name="user_name" required />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Email" name="user_email" type="email" required />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Message" name="message" multiline rows={20}  />
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                <Button type="submit" variant="contained" color="primary" sx={{ width: '60%', mt: 2, height: '70%' }}>
                                    Send Message
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Container>
                        </div>
					</div>

					{/* <div className="socials-container">
						<div className="contact-socials">
							<Socials />
						</div>
					</div> */}

					{/* <ChatBot /> */}

					<div className="page-footer">
						<Footer />
						
					</div>
				</div>
			</div>
			<Dialog open={open} onClose={handleClose}>
                <DialogTitle>Message Sent</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Your message has been successfully sent. We will get back to you as soon as possible.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>OK</Button>
                </DialogActions>
            </Dialog>
		</React.Fragment>
		</ThemeProvider>
	);
};

export default Contact;
