import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./styles/navBar.css";

const NavBar = (props) => {
  const { active } = props;
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 0) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Optional: Use "smooth" for a smooth scrolling effect
    });
  };

  return (
    <React.Fragment>
      <div className="nav-container">
        <nav className="navbar">
          <div className={`nav-background ${hasScrolled ? "scrolled" : ""}`}>
            <ul className="nav-list">
              {["home", "about", "projects", "portfolio", "contact"].map((item) => (
                <li
                  className={`nav-item ${active === item ? "active" : ""}`}
                  key={item}
                >
                  <Link to={`/${item === "home" ? "" : item}`} onClick={scrollToTop}>
                    <span>{item.charAt(0).toUpperCase() + item.slice(1)}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
    </React.Fragment>
  );
};

export default NavBar;
