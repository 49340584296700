import React from "react";
import { Link } from "react-router-dom";
import "./styles/footer.css";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const Footer = () => {
  return (
    <div className="footer">
      <Container className="footer-grid" justifyContent = "center"> 
        <Grid container spacing={3} justifyContent="center" columnSpacing={{xs:2}} >
			<Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'lefts', alignItems: 'center' }}>
            <div className="footer-credits-text">© 2024 Binh Do-Cao</div>
          </Grid>

          <Grid item xs={6} sm={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div className="footer-section">
              <div className="footer-link elsewhere">
                <div className="footer-credits-text">Elsewhere</div>
                <div><a href="https://www.linkedin.com/in/binhdocao/" target="_blank" rel="noopener noreferrer">LinkedIn</a></div>
                <a href="/Binh_Do-Cao_Resume.pdf" target="_blank" rel="noopener noreferrer">Resume</a>
                <div><a href="https://github.com/binhdocao" target="_blank" rel="noopener noreferrer">Github</a></div>
                <div><a href="https://www.instagram.com/binh.docao" target="_blank" rel="noopener noreferrer">IG</a></div>
              </div>
            </div>
          </Grid>
          
          <Grid item xs={6} sm={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div className="footer-section">
              <div className="footer-link elsewhere">
                <div className="footer-credits-text">Contact</div>
                <div><Link to="/contact">Message</Link></div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
