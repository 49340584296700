import React, { useState, useEffect } from "react";
import Chatbot from "react-chatbotify";
import emailjs from "@emailjs/browser";
import "react-chatbotify/dist/react-chatbotify.css";

const options = {
  isOpen: true,
  theme: {
    primaryColor: '#005ccc',
    secondaryColor: '#524F54',
    fontFamily: 'Arial, sans-serif',
    showFooter: false
  },
  audio: { disabled: true },
  bodyStyle: { color: "rgba(255, 255, 255, 0.3)" },
  chatHistory: { disabled: true },
  notification: { disabled: true },
  header: {
    title: <h3 style={{ cursor: "pointer", margin: 0 }}>Binh Do-Cao</h3>,
    showAvatar: true,
    avatar: "logo.png"
  }
};

const ChatUI = () => {
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userMessage, setUserMessage] = useState('');
  const [isReadyToSend, setIsReadyToSend] = useState(false);

  const sendEmail = () => {
    const templateParams = {
      user_name: userName,
      user_email: userEmail,
      message: userMessage
    };

    emailjs.send('service_vcokxu7', 'template_422iazd', templateParams, 'W3ObIqtQDY_myo4vx')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        // Handle successful email sending here (e.g., show a message to the user)
      }, (err) => {
        console.log('FAILED...', err);
        // Handle email sending failure here (e.g., show an error message to the user)
      });
  };

  useEffect(() => {
    if (isReadyToSend) {
      sendEmail();
      setIsReadyToSend(false); // Reset the flag
    }
  }, [isReadyToSend]);

  const flow = {
    start: {
      message: "What's your name?",
      path: "getEmail"
    },
    getEmail: {
      message: (params) => {
        setUserName(params.userInput);
        return "Great! Now, what's your email?";
      },
      path: "getMessage"
    },
    getMessage: {
      message: (params) => {
        setUserEmail(params.userInput);
        return "Almost done! Please type your message.";
      },
      path: "sendEmail"
    },
    sendEmail: {
      message: (params) => {
        setUserMessage(params.userInput);
        setIsReadyToSend(true); // Set the flag to indicate readiness to send the email
        return "Your message is being sent. Thank you!";
      },
      chatDisabled: true
    }
  };

  return (
    <React.Fragment>
      <Chatbot options={options} flow={flow} />
    </React.Fragment>
  );
};

export default ChatUI;
