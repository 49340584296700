import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import INFO from "../data/user";
import SEO from "../data/seo";
import "./styles/articles.css";
import "../components/portfolio/style/base.css";
import "../components/portfolio/style/sandbox.css";
import "../components/portfolio/style/embla.css"
import 'react-tooltip/dist/react-tooltip.css';
import "yet-another-react-lightbox/styles.css";
import Tooltip from '@mui/material/Tooltip';
import Masonry from '@mui/lab/Masonry';
import Lightbox from "yet-another-react-lightbox";

const OPTIONS = { dragFree: true, loop: true ,align:'start'}

const itemData = [
    { src: "/portfolio/1.jpg", title: 'Description of image 1'},
    { src: "/portfolio/7.jpg", title: 'Description of image 7' },
    { src: "/portfolio/2.jpg", title: 'Description of image 2' },
    { src: "/portfolio/3.jpg", title: 'Description of image 3' },
    { src: "/portfolio/4.jpg", title: 'Description of image 4' },
    { src: "/portfolio/5.jpg", title: 'Description of image 5' },
    { src: "/portfolio/6.jpg", title: 'Description of image 6' },
    { src: "/portfolio/7.jpg", title: 'Description of image 7' },
    { src: "/portfolio/8.jpg", title: 'Description of image 8' }
    // Add more images as needed
];



const Portfolio = () => {
	useEffect(() => {
	  window.scrollTo(0, 0);
	}, []);
  
	const currentSEO = SEO.find((item) => item.page === "articles");
	const [lightboxOpen, setLightboxOpen] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(0);
  
	const openLightbox = (index) => {
	  setSelectedIndex(index);
	  setLightboxOpen(true);
	};
  
	return (
	  <React.Fragment>
		<Helmet>
		  <title>{`Portfolio | ${INFO.main.title}`}</title>
		  <meta name="description" content={currentSEO.description} />
		  <meta name="keywords" content={currentSEO.keywords.join(", ")} />
		</Helmet>
  
		<div className="page-content">
		  <NavBar active="portfolio" />
		  <div className="content-wrapper">
			<div className="articles-main-container">
			  <div className="title articles-title">
				{INFO.articles.title}
			  </div>
  
			  <div className="articles-container">
				<div className="articles-wrapper">

				  <div className="image-gallery">
                  <Masonry columns={{ xs: 1, sm: 3 }} spacing={3}>
                    {itemData.map((item, index) => (
                      <div key={index} onClick={() => openLightbox(index)}>
                        <img
                          src={item.src}
                          alt={item.title}
                          style={{ width: "100%", display: "block" }}
                          loading="lazy"
                          data-tip
                          data-for={`tooltip-${index}`} // Ensure unique identifier
                        />
                        <Tooltip id={`tooltip-${index}`} place="bottom" effect="float" type = "light">
                          {/* {item.title} */}
                        </Tooltip>
                      </div>
                    ))}
                  </Masonry>

                  <Lightbox
                    styles={{ container: { backgroundColor: "rgba(255, 255, 255, .5)" } }}
                    open={lightboxOpen}
                    close={() => setLightboxOpen(false)}
                    slides={itemData.map((item) => ({
                      src: item.src,
                      alt: item.title,
                    }))}
                    index={selectedIndex}
                  />
				  </div>
				</div>
			  </div>
			</div>
			<div className="page-footer">
			  <Footer />
			</div>
		  </div>
		</div>
	  </React.Fragment>
	);
  };
  
  export default Portfolio;