import React from "react";
import Project from "./project";
import INFO from "../../data/user";
import Grid from "@mui/material/Grid";

const AllProjects = () => {
  return (
    <Grid container spacing={3} justify="center">
      {INFO.projects.map((project, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Project
            logo={project.logo}
            title={project.title}
            description={project.description}
            linkText={project.linkText}
            link={project.link}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default AllProjects;
